body {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #272727;
}

code {
  font-family: "IBM Plex Mono", monospace;
  color: #cc4125;
}

.text-mono {
  font-family: "IBM Plex Mono", monospace;
}

/* 
Raisin Black #272727
Trident Red #cc4125
Light Blue #afd3e7
Independence (dark purple) #4A4E69
Deep Lemon #F3B61F
 */

.text-link {
  cursor: pointer;
  color: #007bff;
}
.text-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
